import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface DataParams {
    className?: string;
    asImage?: boolean;
    variableName: string;
    jsonPath: string;
    modifiers?: Array<any>;
    toList?: string; // converts array of objects to list of strings by attributeName
    nullValue?: string;
}

export const Data: FC<DataParams&HTMLElement> = (props: DataParams&HTMLElement) => {
    const { variableName, jsonPath, modifiers, toList, className, asImage, nullValue } = props;
    const [data, setData] = useState<any>(null);
    const [id, setId] = useState<any>("1");

    const global = useSelector((state: any) => state.global.variables)

    useEffect(() => {
        console.log("Data mounted");
    }, []);

    useEffect(() => {
        try {
            if(global && global[variableName]) {
                let res = global[variableName];
                if(res.id) {
                    setId(res.id);
                }
                const jsonPathParts = jsonPath.split('.');
                for(const part of jsonPathParts) {
                    res = res[part];
                }
                // Process modifiers
                try {
                    if(modifiers && res) {
                        for(const modifier of modifiers) {
                            res = modifier(res);
                        }
                    }
                }
                catch(e) {
                    //console.trace("Error processing modifiers", props, e);
                }

                try {
                    if(toList) {
                        res = res.map((item: any) => item[toList]);
                    }
                }
                catch(e) {                    
                    //console.trace("Error processing toList", props, e);
                }

                setData(res);
            }
        }
        catch(e) {
            //console.trace("Error getting data", props, e);
        }
    }, [global]);

    if(data && Array.isArray(data) && asImage && toList) {
        return (
            <>
                {data.map((item: any, ix) => 
                    <img key={'data_img'+id+ix} className={className} src={item} />
                )}
            </>
        )
    }
    if(data && Array.isArray(data) && toList) {
        return <>{data.join(", ")}</>;
    }
    if(!data || Array.isArray(data) || typeof data === "object") {
        return <>{nullValue}</>;
    }
    return<>{data}</>;
}
