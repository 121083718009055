import React, { FC, useEffect, useState } from "react";
import JsxParser from 'react-jsx-parser'
import { getWidget } from "./util/helper";
import { set } from "date-fns";
import { AutomationRunNow } from "./components/AutomationRunNow";
import { Loader } from "./components/Loader";
import { AutomationInputData } from "./components/AutomationInputData"; 
import { useSelector } from "react-redux";
import { Data } from "./components/Data";
//import { EventClient } from "./components/EventClient";

interface TestProps {
    name: string;
}

const JSXParser: any = JsxParser;

interface WidgetParams {
    widgetId: string;
    deploymentId: string;
    apiKey: string;
}

const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);


export const AppContainer: FC = (props) => {
    const [widget, setWidget] = useState<any>(null);

    const global = useSelector((state: any) => state.global.variables)

    const mountStyles = (stylesStr: string) => {
        const styleTag = document.createElement('style');
        styleTag.textContent = stylesStr;
        document.head.appendChild(styleTag);
    }

    useEffect(() => {
        console.log("AppContainer mounted");
        getWidget().then((widget) => {
            setWidget(widget);
            if(widget.stylesheet) {
                mountStyles(widget.stylesheet);
            }
        });
    }, []);

    useEffect(() => {
        if (widget) {
            console.log('widget', widget);
        }
    }, [widget]);

    if (!widget) {
        return <div>Loading...</div>;
    }


    return (
        <>
            {widget && <JSXParser
                disableKeyGeneration
                bindings={{ capitalize }}
                components={{ AutomationRunNow, AutomationInputData, Data, Loader }}
                jsx={widget.definition}
            />}
        </>
    );
}
