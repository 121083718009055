
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type VariableUpdateAction = {
    key: string,
    value: any
}

type GlobalState = {
    variables: {[key: string]: any}
}

const initialState = {
    variables: {}
} as GlobalState

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateVariable: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            state.variables[key] = value;
        },
    },
})

export const { updateVariable } = globalSlice.actions

export default globalSlice.reducer