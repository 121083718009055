import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { getAll } from "../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { updateVariable } from "../redux/globalSlice";

interface LoaderDataParams {
    url: string,
    headers?: {[index: string]: string};
    getFirstItem?: boolean;
    variableName: string;
}

export const Loader: FC<LoaderDataParams&HTMLElement> = (props: LoaderDataParams&HTMLElement) => {
    const { url, headers, getFirstItem, variableName } = props;

    const dispatch = useDispatch();

    const options = headers ? {headers} : undefined;

    const getAutomation = async () => {
        getAll(url, options)
            .then((data) => {
                if(getFirstItem && data && data.length > 0) {
                    data = data[0];                
                }
                console.log({url, variableName, data});
                dispatch(updateVariable({key: variableName, value: data}));
            });
    }

    useEffect(() => {
        console.log("Loader mounted");
        getAutomation();
    }, []);

    return <></>;
}
